<template>
<div class="mt-1" v-loading="loadingData">
    <div class="nurseOffice">
        <!-- <div class="mt10 mb-3">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">Cтационар</el-breadcrumb-item>
          <el-breadcrumb-item>Кабинет медсестра</el-breadcrumb-item>
        </el-breadcrumb>
      </div>-->
        <div class="title_resultsPatsnt">
            <div class="title_resultsPatsnt_text">{{ $t('message.dailyTreatment_patient') }}</div>
            <el-button v-if="lastHistory.is_ended != 0" disabled v-can="'dailyTreatments.create'" size="mini" @click="drawer.create.status = true" icon="el-icon-circle-plus-outline">
                {{ $t('message.add_medicine') }}
            </el-button>
            <el-button v-else v-can="'dailyTreatments.create'" size="mini" @click="drawer.create.status = true" icon="el-icon-circle-plus-outline">
                {{ $t('message.add_medicine') }}
            </el-button>
        </div>
        <div class="nurseOffice_tabs">
            <el-tabs type="border-card">
                <el-tab-pane :label="$t('message.drug')">
                    <Medicine />
                </el-tab-pane>
                <!-- end  el-tab-pane  -->
                <el-tab-pane :label="$t('message.indicator')">
                    <Result />
                </el-tab-pane>
                <!-- end  el-tab-pane  -->
                <el-tab-pane :label="$t('message.defects')">
                    <DefectsModal />
                </el-tab-pane>
                <!-- end  el-tab-pane  -->
            </el-tabs>
        </div>
    </div>

    <el-drawer size="100%" :wrapperClosable="false" :visible.sync="drawer.create.status" :ref="drawer.create.name" @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
        <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
    </el-drawer>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import Medicine from "./components/medicine";
import Result from "./components/result";
import DefectsModal from "./components/defectsModal";
import CrmCreate from "./components/addMedicl";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
export default {
    mixins: [list, drawer],
    name: "dailyTreatment",
    components: {
        CrmCreate,
        Result,
        DefectsModal,
        Medicine,
    },
    data() {
        return {
            loadingData: false,
            selectedModel: {},
            drawer: {
                create: {
                    name: "create",
                    status: false,
                    component: 'componentDrawerCreate'
                }
            }
        }
    },
    computed: {
        getId() {
            return this.$route.params.id;
        },
        ...mapGetters({
            activeCode: 'patients/activeCode',
            list: 'dailyTreatments/list',
            lastHistory: 'dailyTreatments/lastHistory',
            columns: "dailyTreatments/columns",
            pagination: "dailyTreatments/pagination",
            filter: "dailyTreatments/filter",
            sort: "dailyTreatments/sort",
            dailyTreatment: 'dailyTreatments/model',
        }),
        actions: function () {
            return ['edit', 'delete'];
        }
    },
    watch: {
        lastHistory: {
            handler: function (val, oldVal) {
                if (val) {
                    this.updateList({
                            patient_id: this.getId,
                            id: val.id
                        })
                        .then(res => {
                        }).catch(err => {
                            this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                        });
                    this.updateListPatient({
                            patient_id: this.getId,
                            id: val.id
                        })
                        .then(res => {
                        }).catch(err => {
                            this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                        });
                    this.updateDefect({
                            patient_id: this.getId,
                            id: val.id
                        })
                        .then(res => {
                        }).catch(err => {
                            this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                        });
                }
            },
            deep: true
        },
    },
    created() {},
    async mounted() {
        this.loadingData = true;
        var vm = this;
        if (_.isArray(this.lastHistory) && this.lastHistory.length > 0) {
            this.updateList({
                    patient_id: vm.getId,
                    id: vm.lastHistory.id
                })
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                    this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                });
            this.updateListPatient({
                    patient_id: vm.getId,
                    id: vm.lastHistory.id
                })
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                    this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                });
            this.updateDefect({
                    patient_id: vm.getId,
                    id: vm.lastHistory.id
                })
                .then(res => {
                    this.loadingData = false;
                }).catch(err => {
                    this.loadingData = false;
                    this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                });
        }
    },
    methods: {
        ...mapActions({
            updateList: 'dailyTreatments/index',
            updateDefect: 'dailyTreatments/defect',
            updateListPatient: 'patientIndicators/index',
            updateSort: "dailyTreatments/updateSort",
            updateFilter: "dailyTreatments/updateFilter",
            updateColumn: "dailyTreatments/updateColumn",
            updatePagination: "dailyTreatments/updatePagination",
            editModel: 'dailyTreatments/show',
            empty: 'dailyTreatments/empty',
            delete: 'dailyTreatments/destroy',
            refreshData: 'dailyTreatments/refreshData',
        }),

        fetchData() {
            var vm = this;
            if ((_.isArray(this.lastHistory) && this.lastHistory.length > 0) || this.lastHistory.id) {
                this.loadingData = true;
                this.updateList({
                        patient_id: vm.getId,
                        id: vm.lastHistory.id
                    })
                    .then(res => {
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                        this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                    });

                this.updateListPatient({
                        patient_id: this.getId,
                        id: this.lastHistory.id
                    })
                    .then(res => {
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                        this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                    });

                this.updateDefect({
                        patient_id: this.getId,
                        id: this.lastHistory.id
                    })
                    .then(res => {
                        this.loadingData = false;
                    }).catch(err => {
                        this.loadingData = false;
                        this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          })
                    });
            }

        },
        refresh() {
            this.refreshData()
                .then(res => {
                    this.filterForm = JSON.parse(JSON.stringify(this.filter));
                })
                .catch(err => {

                })
        },
        destroy(model) {
            this.delete(model.id)
                .then(res => {
                    this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                    this.fetchData()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        emptyModel() {
            this.empty()
        },
    }
};
</script>
